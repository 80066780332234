import { FC, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Form, Step, StepperContext, useEditable } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'

import { EmissionCardPageContext } from '@pages/DFAEmission/DFAEmissionCardPage/EmissionCardPageContext'

import { UploadInput } from '../../Form'


export const ApplicationToIssueAccess: FC<{ stepIndex: number; isModification: boolean }> = ({
    stepIndex,
    isModification,
}) => {
    const [form] = Form.useForm()
    const userUuid = useSelector(authSelector.selectUserUid)
    const { editable, currentStep, stepsTotal, values } = useContext(StepperContext)
    const { setDisabledActionButtons } = useContext(EmissionCardPageContext)

    const isEdit = useEditable(currentStep, stepIndex, editable)
    const allowEdit =
        isModification &&
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(stepIndex))

    useEffect(() => {
        form.setFieldsValue(values)
    }, [values])

    useEffect(() => {
        setDisabledActionButtons(isEdit)
    }, [isEdit])

    return (
        <Step
            stepIndex={stepIndex}
            form={form}
            stepTitle="Заявление на допуск к выпуску"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <UploadInput
                className="formComponentItem form-item--upload"
                name="admission_application"
                label="Файл заявления"
                uneditable={isEdit}
                required
                title="Загрузите подписанное Заявление о допуске ЦФА к выпуску. Допустимый формат файлов JPG, JPEG, TIFF, PDF, PNG, DOC, DOCX, TXT, RTF, ODT. Общий вес не более 25 МБ"
                accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
                maxSizeMb={5}
                maxCount={1}
            />
            {values?.emitter_id === userUuid && (
                <UploadInput
                    className="formComponentItem form-item--upload"
                    uneditable={isEdit}
                    required
                    name="admission_application_signature"
                    label="Файл подписи"
                    title="Загрузите файл подписи с расширением SIG, SGN"
                    accept=".sig,.sgn"
                    maxSizeMb={5}
                    maxCount={1}
                />
            )}
        </Step>
    )
}