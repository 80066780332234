import { FC, useCallback, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Moment } from 'moment'

import { TAddDates, TCouponPaymentDate, TPeriodType } from '@dltru/dfa-models'
import { CouponPayments, StepperContext, VIEW_DATE_FORMAT, useEditable } from '@dltru/dfa-ui'

import { paymentsSchedulerSlice } from '@store/paymentsScheduler'
import { paymentSchedulerSelector } from '@store/paymentsScheduler/selectors'

import { EmissionCardPageContext } from '@pages/DFAEmission/DFAEmissionCardPage/EmissionCardPageContext'


interface IComponentProps {
    backFromFirstStep?: () => void
    stepIndex: number
    saveAfterEdit?: boolean
    allowEdit?: boolean
}

export const CouponPaymentsStep: FC<IComponentProps> = ({
    backFromFirstStep,
    stepIndex,
    saveAfterEdit,
    allowEdit,
}) => {
    const { values, currentStep, editable } = useContext(StepperContext)
    const { setDisabledActionButtons } = useContext(EmissionCardPageContext)
    const dates = useSelector(paymentSchedulerSelector.getDates)
    const reduxDispatch = useDispatch()
    const isEdit = useEditable(currentStep, stepIndex, editable)

    useEffect(() => {
        setDisabledActionButtons(isEdit)
    }, [isEdit])

    useEffect(() => {
        if (values?.coupon_payment_calendar?.length) {
            reduxDispatch(paymentsSchedulerSlice.actions.setDates(values.coupon_payment_calendar))
        }

        return function cleanup() {
            reduxDispatch(paymentsSchedulerSlice.actions.resetDates())
        }
    }, [])

    const addDates = useCallback((data: TAddDates) => {
        if (data.period_type === TPeriodType.no_repeat) {
            reduxDispatch(paymentsSchedulerSlice.actions.addDates(data.dates))
            return
        }

        const redeem_date = values?.redeem_date
            ? (values?.redeem_date as Moment).format(VIEW_DATE_FORMAT)
            : undefined

        reduxDispatch(
            paymentsSchedulerSlice.actions.addOnceInNMonthsDates({ ...data, redeem_date }),
        )
    }, [])

    const saveAfterEditDates = useCallback((dates: TCouponPaymentDate[]) => {
        reduxDispatch(paymentsSchedulerSlice.actions.saveEditedDates(dates))
    }, [])

    return (
        <>
            <CouponPayments
                backFromFirstStep={backFromFirstStep}
                allowEdit={allowEdit}
                stepIndex={stepIndex}
                saveAfterEdit={saveAfterEdit}
                dates={dates}
                addDates={addDates}
                saveAfterEditDates={saveAfterEditDates}
            />
        </>
    )
}