import { FC } from 'react'

import cn from 'classnames'

import { SupplyInvestedTypeTranslate } from '@dltru/dfa-models/src/models/dfa/countDfa'
import { WrappedInput, WrappedSelect, currentNumber, dfaValidators } from '@dltru/dfa-ui'

import style from './style.m.less'

interface ICountDFAProps {
    isPriceTypeDynamic: boolean
    isEdit: boolean
    isDiapasonPrice: boolean
}

const optionsSupplyInvestedType = Object.entries(SupplyInvestedTypeTranslate).map(
    ([key, value]) => ({
        key,
        value: key,
        label: value,
    }),
)

export const CountDFA: FC<ICountDFAProps> = ({ isPriceTypeDynamic, isEdit, isDiapasonPrice }) => {
    return (
        <>
            <div className={style.countDFAWrap}>
                <WrappedSelect
                    className={cn(style.select, 'formComponentItemHalfWidth')}
                    options={optionsSupplyInvestedType}
                    disabled={isPriceTypeDynamic}
                    name="supply_invested_type"
                    label="Количество выпускаемых ЦФА"
                    required
                    uneditable={isEdit}
                />
                <div className={cn(!isEdit ? style.priceWrap : style.priceWrap_disabled)}>
                    {!isDiapasonPrice && (
                        <WrappedInput
                            className={cn(style.fixedCountInput, 'formComponentItem')}
                            name="max_supply_invested"
                            placeholder="Укажите количество выпускаемых ЦФА"
                            uneditable={isEdit}
                            required
                            rules={[{ validator: dfaValidators.amount() }]}
                            normalize={currentNumber}
                        />
                    )}
                    {isDiapasonPrice && (
                        <div className={style.diapasonPrice}>
                            <WrappedInput
                                placeholder="Мин. размер выпуска"
                                className="formComponentItem"
                                name="min_supply_invested"
                                uneditable={isEdit}
                                required
                                rules={[{ validator: dfaValidators.amount() }]}
                                normalize={currentNumber}
                            />
                            <span
                                className={
                                    !isEdit ? style.priceSeparator : style.priceSeparator_disabled
                                }
                            >
                                —
                            </span>
                            <WrappedInput
                                placeholder="Макс. размер выпуска"
                                className="formComponentItem"
                                name="max_supply_invested"
                                uneditable={isEdit}
                                required
                                rules={[{ validator: dfaValidators.amount() }]}
                                normalize={currentNumber}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
