import { DfaStatusEnum, IDfaFront } from '@dltru/dfa-models'
import { AccessibilityStep, IStep, IStepper, StepperC } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import { authSelector } from '@store/auth/selectors'

import api from '@services/api'

import {
    ApplicationToIssueAccess,
    DecisionDocument,
    IssueAppliedDocs,
    IssueConditions,
} from '@components/DFADetails/components'
import { InvestorsBlock } from '@components/DFADetails/components/InvestorsBlock/InvestorsBlock'
import { CouponPaymentsStep } from './components/CouponPaymentsStep/CouponPaymentsStep'

type ComponentProps = Omit<IStepper<IDfaFront>, 'children'> & Pick<IStep, 'saveAfterEdit'>
export const DFADetailsStepper: FC<ComponentProps> = ({ initialValues, ...props }) => {
    const uuid = useSelector(authSelector.selectUserUid)
    const isEmitter = initialValues?.emitter_id === uuid
    const isModification = initialValues?.status === DfaStatusEnum.modification && isEmitter
    return (
        <StepperC {...props} initialValues={initialValues}>
            <IssueConditions stepIndex={0} isModification={isModification} isEmitter={isEmitter} />
            <CouponPaymentsStep stepIndex={1} allowEdit={isModification} />
            <AccessibilityStep
                isModification={isModification}
                CustomInvestorsBlock={isEmitter ? InvestorsBlock : undefined}
                getUids={api.lib.getUidsByInnType}
                stepIndex={2}
            />
            <ApplicationToIssueAccess stepIndex={3} isModification={isModification} />
            <DecisionDocument stepIndex={4} isModification={isModification} />
            <IssueAppliedDocs stepIndex={5} isModification={isModification} />
        </StepperC>
    )
}
