import {
    DfaStatusEnum,
    IssueAvailableInvestorsType,
    issueAvailableInvestorsTypeLimitsTranslate,
    issueAvailableInvestorsTypeTranslate,
} from '@dltru/dfa-models'
import {
    CardDivider,
    IValues,
    RedeemDateBlock,
    RedeemPriceBlock,
    WrappedDateItem,
    WrappedInput,
    WrappedSelect,
    currentFloatNumber,
    dfaValidators,
} from '@dltru/dfa-ui'
import moment, { Moment } from 'moment'
import FieldContext from 'rc-field-form/lib/FieldContext'
import { FC, useContext, useEffect, useState } from 'react'

interface IPriceSourceStaticProps {
    values: IValues | null
    form: any
    isEdit: boolean
    isManual: boolean
    setRedeemDate?: () => void
}

const optionsIssueAvailableInvestorsType = Object.entries(issueAvailableInvestorsTypeTranslate).map(
    ([key, value]) => ({
        key,
        value: key,
        label: value,
    }),
)

const optionsIssueAvailableInvestorsLimitsType = Object.entries(
    issueAvailableInvestorsTypeLimitsTranslate,
).map(([key, value]) => ({
    key,
    value: key,
    label: value,
}))

export const PriceSourceStatic: FC<IPriceSourceStaticProps> = ({
    values,
    isEdit,
    isManual,
    setRedeemDate,
}) => {
    const isUnqualifiedType =
        values?.issue_available_investors_type ===
        IssueAvailableInvestorsType.qualified_and_unqualified

    // const isPlatform = values?.decision_making_type === DecisionMakingType.platform
    const isPreliminaryCheckStatus = values?.status === DfaStatusEnum.preliminary_check
    const hideQualLimitBlock =
        isPreliminaryCheckStatus || values?.status === DfaStatusEnum.modification
    /*const hideTellerBlock =
        isPreliminaryCheckStatus || (values?.status === DfaStatusEnum.modification && isPlatform)
        */
    const { getFieldValue, validateFields } = useContext(FieldContext)
    const [issueStartDate, setIssueStartDate] = useState<Moment | undefined>()
    const [issueEndDate, setIssueEndDate] = useState<Moment | undefined>()
    useEffect(() => {
        setIssueStartDate(getFieldValue('issue_start_date'))
        setIssueEndDate(getFieldValue('issue_end_date') ?? getFieldValue('issue_start_date'))
    }, [isEdit])
    const onChangeIssueStartDate = (value: Moment | null) => {
        setIssueStartDate(value ?? undefined)
        if (getFieldValue('issue_end_date')) {
            validateFields(['issue_end_date'])
        } else {
            setIssueEndDate(value ?? undefined)
            if (getFieldValue('redeem_date')) {
                validateFields(['redeem_date'])
            }
        }
    }
    const onChangeIssueEndDate = (value?: Moment | null) => {
        setIssueEndDate(value ?? undefined)
        if (getFieldValue('redeem_date')) {
            validateFields(['redeem_date'])
        }
    }

    return (
        <>
            {!isPreliminaryCheckStatus && (
                <>
                    <WrappedInput
                        className="formComponentItem"
                        label="Цена ЦФА, руб."
                        name="price_per_dfa"
                        placeholder="Цена единицы"
                        uneditable={isEdit}
                        required
                        rules={[{ validator: dfaValidators.price }]}
                        normalize={currentFloatNumber}
                    />
                    <WrappedDateItem
                        className="formComponentItem"
                        label="Дата начала размещения ЦФА"
                        name="issue_start_date"
                        uneditable={isEdit}
                        required
                        rules={[{ validator: dfaValidators.issueStartDate }]}
                        showTime={{ defaultValue: moment('10:00', 'HH:mm') }}
                        onChange={onChangeIssueStartDate}
                    />
                    {!isManual && (
                        <WrappedDateItem
                            className="formComponentItem"
                            label="Дата окончания размещения ЦФА"
                            name="issue_end_date"
                            uneditable={isEdit}
                            required
                            rules={[{ validator: dfaValidators.issueEndDate(issueStartDate) }]}
                            showTime={{ defaultValue: moment('20:00', 'HH:mm') }}
                            onChange={onChangeIssueEndDate}
                        />
                    )}
                    <CardDivider />

                    <div className="formRow">
                        <WrappedInput
                            className="formComponentItem formComponentItem--xl"
                            label="Условие признания выпуска состоявшимся"
                            name="issue_conditions"
                            uneditable={isEdit}
                            multiline
                            required={isManual}
                            disabled={!isManual}
                        />
                    </div>
                    <CardDivider />

                    <RedeemPriceBlock uneditable={isEdit} />
                    <RedeemDateBlock
                        uneditable={isEdit}
                        dateForValidate={issueEndDate}
                        setRedeemDate={setRedeemDate}
                    />
                </>
            )}
            {!hideQualLimitBlock && (
                <>
                    <CardDivider />
                    <WrappedSelect
                        className="formComponentItem"
                        label="ЦФА доступны для приобретения"
                        name="issue_available_investors_type"
                        options={optionsIssueAvailableInvestorsType}
                        uneditable={isEdit}
                    />
                    <br />
                    {isUnqualifiedType && (
                        <WrappedSelect
                            className="formComponentItem"
                            label="Приобретение ЦФА физическими лицами - неквалифицированными инвесторами"
                            name="issue_available_investors_type_limits"
                            options={optionsIssueAvailableInvestorsLimitsType}
                            uneditable={isEdit}
                        />
                    )}
                </>
            )}
        </>
    )
}
